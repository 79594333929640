import { useEffect, useRef } from 'react';

export default function usePropRef<T>(prop: T) {
  const ref = useRef(prop);

  useEffect(() => {
    ref.current = prop;
  }, [prop]);

  return ref as { readonly current: T };
}
