import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Status, SuccessRes, FailureRes, Res } from 'requests/api';

interface RequestOptions<T = unknown, E = { error: string }> {
  skip?: boolean;
  onSuccess?(data: SuccessRes<T>): void;
  onFailure?(err: FailureRes<E>): void;
}

type ReqCallback<T = unknown, E = { error: string }> = () => Promise<
  SuccessRes<T> | FailureRes<E>
>;

type ReqCallbackWithParams<
  P extends object = {},
  T = unknown,
  E = { error: string }
> = (params: P) => Promise<SuccessRes<T> | FailureRes<E>>;

/** Contains methods for refetching and resetting requests */
interface Callbacks<T, E> {
  /** Makes another request (bypasses skip option) */
  refetch(): Promise<void>;
  /** Resets the response to the last Success or Idle response */
  reset(): void;
  /** Manually updates a response*/
  setResponse(res: Res<T, E>): void;
}

export function createRequestHook<T, E>(fn: ReqCallback<T, E>) {
  return (opts?: RequestOptions<T, E>) => {
    const skip = opts?.skip ?? false;
    const onSuccess = opts?.onSuccess;
    const onFailure = opts?.onFailure;
    const [lastSuccess, setLastSuccess] = useState<SuccessRes<T> | null>(null);
    const [res, setRes] = useState<Res<T, E>>({
      type: Status.Idle
    });

    useEffect(() => {
      if (skip) return;
      if (res.type !== Status.Success) {
        setRes({ type: Status.Pending });
      }
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip]);

    async function refetch() {
      const res = await fn();
      setRes(res);
      switch (res.type) {
        case Status.Success: {
          setLastSuccess(res);
          onSuccess?.(res);
          break;
        }
        case Status.Failure: {
          onFailure?.(res);
          break;
        }
      }
    }

    function reset() {
      setRes(lastSuccess ?? { type: Status.Idle });
    }

    function setResponse(res: Res<T, E>) {
      setRes(res);
    }

    const callbacks: Callbacks<T, E> = {
      refetch,
      reset,
      setResponse
    };

    return [res, callbacks] as [Res<T, E>, typeof callbacks];
  };
}

export function createRequestHookWithParams<P extends object, T, E>(
  fn: ReqCallbackWithParams<P, T, E>
) {
  return (params: P, opts?: RequestOptions<T, E>) => {
    const requestId = JSON.stringify(params);
    const skip = opts?.skip ?? false;
    const onSuccess = opts?.onSuccess;
    const onFailure = opts?.onFailure;
    const [lastSuccess, setLastSuccess] = useState<SuccessRes<T> | null>(null);
    const [res, setRes] = useState<Res<T, E>>({
      type: Status.Idle
    });

    useEffect(() => {
      if (skip) return;
      if (res.type !== Status.Success) {
        setRes({ type: Status.Pending });
      }
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestId, skip]);

    async function refetch() {
      const res = await fn(params);
      setRes(res);
      switch (res.type) {
        case Status.Success: {
          setLastSuccess(res);
          onSuccess?.(res);
          break;
        }
        case Status.Failure: {
          onFailure?.(res);
          break;
        }
      }
    }

    function reset() {
      setRes(lastSuccess ?? { type: Status.Idle });
    }

    function setResponse(res: Res<T, E>) {
      setRes(res);
    }

    const callbacks: Callbacks<T, E> = {
      refetch,
      reset,
      setResponse
    };

    return [res, callbacks] as [Res<T, E>, typeof callbacks];
  };
}

export interface PaginatedListStore<
  P extends { offset: number; limit: number },
  T extends { data: unknown[]; count: number },
  E extends { error?: string } = { error?: string }
> {
  params?: P;
  data: T['data'];
  total: number;
  isLoading: boolean;
  error: string;
  fetch(p: P): Promise<SuccessRes<T> | FailureRes<E>>;
  fetchAll(p: P, limit: number): Promise<SuccessRes<T> | FailureRes<E>>;
  setData: Dispatch<SetStateAction<T['data']>>;
  setTotal: Dispatch<SetStateAction<number>>;
}

export function usePaginatedList<
  P extends { offset: number; limit: number },
  T extends { data: unknown[]; count: number },
  E extends { error?: string } = { error?: string }
>(
  f: (params: P) => Promise<SuccessRes<T> | FailureRes<E>>,
  defaultParams?: P
): PaginatedListStore<P, T> {
  const [params, setParams] = useState<P | undefined>(defaultParams);
  const [data, setData] = useState<T['data']>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  useEffect(() => {
    if (params) {
      fetch(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return {
    params,
    data,
    total,
    isLoading,
    error,
    fetch,
    fetchAll,
    setData,
    setTotal
  };
  async function fetchAll(p: P, limit: number) {
    setParams(p);
    const results: T['data'] = [];
    let total = 1;
    for (let offset = 0; offset < total; offset += limit) {
      const res = await f({
        ...p,
        offset,
        limit
      } as any);
      switch (res.type) {
        case Status.Failure: {
          setError(res.body?.error ?? res.error);
          return res;
        }
        case Status.Success: {
          total = res.body.count;
          results.push(...res.body.data);
          break;
        }
      }
    }
    setError('');
    setData(results);
    setTotal(total);
    return {
      type: Status.Success,
      status: 200,
      body: {
        data: results,
        count: total
      }
    } as SuccessRes<T>;
  }
  async function fetch(p: P) {
    setParams(p);
    setIsLoading(true);
    const { offset: _offset0, limit: _limit0, ...a } = p;
    const { offset: _offset1, limit: _limit1, ...b } = params ?? p;
    const shouldReset = JSON.stringify(a) !== JSON.stringify(b);
    if (shouldReset) {
      setData(() => []);
    }
    const res = await f(p);
    switch (res.type) {
      case Status.Failure: {
        setError(res.body?.error ?? res.error);
        break;
      }
      case Status.Success: {
        setError('');
        setData(data => {
          const nextData = [...data];
          // replace subset of data with latest results
          nextData.splice(p.offset, p.limit, ...res.body.data);
          return nextData;
        });
        setTotal(res.body.count);
        break;
      }
    }
    setIsLoading(false);
    return res;
  }
}
