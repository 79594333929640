"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCookie = exports.cookie = exports.clear = exports.save = void 0;
var react_1 = require("react");
function domain() {
    if (typeof window === 'undefined')
        return '';
    var _a = window.location.hostname, hostname = _a === void 0 ? '' : _a;
    return hostname === 'localhost'
        ? ''
        : hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);
}
var NAME = 'parsec_login';
var DEFAULT_COOKIE = {
    token: '',
    redirect: ''
};
function set(cookie) {
    if (typeof document !== 'undefined')
        document.cookie = cookie;
}
function save(input) {
    var prev = exports.cookie();
    var value = JSON.stringify(__assign(__assign({}, prev), input));
    var secure = window.location.protocol === 'https:' ? 'secure;' : '';
    set(NAME + "=" + value + ";domain=" + domain() + ";path=/;SameSite=Strict;" + secure + "max-age=31536000");
}
exports.save = save;
function clear() {
    set(NAME + "=;domain=" + domain() + ";path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT;");
}
exports.clear = clear;
exports.cookie = (function () {
    var _a;
    var prev = typeof document !== 'undefined' ? document.cookie : '';
    var memo = (_a = parseCookie(prev)) === null || _a === void 0 ? void 0 : _a.parsec_login;
    return function () {
        var _a;
        var next = typeof document !== 'undefined' ? document.cookie : '';
        // Do string comparson to avoid parsing cookie each time
        if (prev !== next) {
            // If the cookie changes, parse and memoize the latest auth state
            memo = (_a = parseCookie(next)) === null || _a === void 0 ? void 0 : _a.parsec_login;
            prev = next;
        }
        return memo || DEFAULT_COOKIE;
    };
})();
function parseCookie(cookie) {
    try {
        return cookie.split(';').reduce(function (acc, c) {
            var _a, _b, _c;
            var _d = c
                .trim()
                .split(/=(.+)/), key = _d[0], val = _d[1]; // The value may contain = so only split on first instance
            try {
                return __assign(__assign({}, acc), (_a = {}, _a[key] = JSON.parse(val), _a));
            }
            catch (e) {
                try {
                    var json = JSON.parse(decodeURIComponent(val));
                    save(json.token);
                    return __assign(__assign({}, acc), (_b = {}, _b[key] = json, _b));
                }
                catch (e) {
                    return __assign(__assign({}, acc), (_c = {}, _c[key] = val, _c));
                }
            }
        }, {});
    }
    catch (err) {
        // eslint-disable-next-line no-console
        console.error('Could not parse cookie');
        return {};
    }
}
function useCookie() {
    var _a = react_1.useState(exports.cookie()), state = _a[0], setState = _a[1];
    react_1.useEffect(function () {
        var timer = setInterval(function () {
            var result = exports.cookie();
            if (result.token !== state.token || result.redirect !== state.redirect) {
                setState(function (state) { return (__assign(__assign({}, state), result)); });
            }
        }, 100);
        return function () { return clearInterval(timer); };
    }, [setState, state.token, state.redirect]);
    return state;
}
exports.useCookie = useCookie;
