// libraries
import React from 'react';
import { css, keyframes } from '@emotion/core';

interface Props {
  className?: string;
}

export default function Loader(props: Props) {
  const { className } = props;

  return (
    <svg
      className={className}
      css={loader}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        transform="rotate(1.33 12 12)"
        fill="none"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke="#fff"
      >
        <circle opacity=".4" cx="12" cy="12" r="11" />
        <path d="M12 1c6.075 0 11 4.925 11 11" />
      </g>
    </svg>
  );
}

const animation = keyframes`
  0%, {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const loader = css`
  animation-name: ${animation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  display: block;
  width: 100%;
  height: 100%;

  > svg > g > circle,
  > svg > g > path {
    fill: currentColor;
  }
`;
