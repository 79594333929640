"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redirect = exports.runAuthRedirect = exports.resetAuthRedirect = exports.setAuthRedirect = exports.getAuthRedirect = void 0;
var cookie_1 = require("@parsec/cookie");
function getAuthRedirect() {
    var redirect = cookie_1.cookie().redirect;
    return redirect;
}
exports.getAuthRedirect = getAuthRedirect;
function setAuthRedirect(url) {
    var token = cookie_1.cookie().token;
    cookie_1.save({ token: token, redirect: url });
}
exports.setAuthRedirect = setAuthRedirect;
function resetAuthRedirect() {
    var token = cookie_1.cookie().token;
    if (!token)
        cookie_1.clear();
    else
        cookie_1.save({ token: token, redirect: '' });
}
exports.resetAuthRedirect = resetAuthRedirect;
function runAuthRedirect() {
    var url = getAuthRedirect();
    if (url) {
        resetAuthRedirect();
        redirect(url);
        return true;
    }
    return false;
}
exports.runAuthRedirect = runAuthRedirect;
function redirect(url) {
    if (typeof window !== 'undefined')
        window.location.assign(url);
}
exports.redirect = redirect;
