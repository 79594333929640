import { useCookie } from '@parsec/cookie';
import { useEffect } from 'react';
import { runAuthRedirect, setAuthRedirect, redirect } from '@parsec/redirect';

/** Redirects the user away from the page they are on if they are logged out. Returns `true` if user is authenticated */
export function useRedirectWhenUnauthenticated() {
  const { token } = useCookie();

  useEffect(() => {
    // If logged out...
    if (!token) {
      if (typeof window !== 'undefined') {
        const search = window.location?.search
          ? window.location.search + '&cachebust'
          : '?cachebust';
        // Set the localStorage redirect to the current page
        setAuthRedirect(`${window.location?.pathname}${search}`);
      }
      // Then redirect to login page
      redirect('/login');
    }
  }, [token]);

  return !!token;
}

/** Redirects the user away from the page they are on if they are logged in. Returns `true` if user is not authenticated */
export function useRedirectWhenAuthenticated() {
  const { token } = useCookie();
  useEffect(() => {
    // If logged in...
    if (token) {
      // Use localStorage redirect
      if (!runAuthRedirect()) {
        // If no redirect was set, go to settings with nav prompt open
        redirect('/settings/account/?nav_prompt=true&cachebust');
      }
    }
  }, [token]);

  return !token;
}
