import { cookie } from '@parsec/cookie';
import { request, Method, API, User, Status } from './api';

// Resend Account Confirmation Email

export function resendAccountConfirmationEmail() {
  return request({
    type: Method.POST,
    url: `${API.Kessel}/me/send-confirmation-email`,
    headers: { Authorization: `Bearer ${cookie().token}` }
  });
}

// Get Auth User

interface GetMeRes {
  data: User;
}
export function getMe() {
  return request<GetMeRes>({
    type: Method.GET,
    url: `${API.Kessel}/me?v=1`,
    headers: { Authorization: `Bearer ${cookie().token}` }
  });
}

// Update Me

type UpdateMeReq =
  | { email: string }
  | { name: string }
  | { email: string; name: string };
export function updateMe(body: UpdateMeReq) {
  return request({
    type: Method.PUT,
    url: `${API.Kessel}/me`,
    headers: { Authorization: `Bearer ${cookie().token}` },
    body
  });
}

// Deactivate Account

export function deactivateAccount() {
  return request({
    type: Method.DELETE,
    url: `${API.Kessel}/me`,
    headers: { Authorization: `Bearer ${cookie().token}` }
  });
}

// Update Account Password

interface UpdateAccountPasswordReq {
  current_password: string;
  password: string;
}
export function updateAccountPassword(body: UpdateAccountPasswordReq) {
  return request({
    type: Method.POST,
    url: `${API.Kessel}/me/password`,
    headers: { Authorization: `Bearer ${cookie().token}` },
    body
  });
}

// Remove Account Avatar

export function removeAccountAvatar() {
  return request({
    type: Method.DELETE,
    url: `${API.Kessel}/me/avatar`,
    headers: { Authorization: `Bearer ${cookie().token}` }
  });
}

// Update Account Avatar

interface UpdateAccountAvatarReq {
  image: File;
}
export async function updateAccountAvatar({ image }: UpdateAccountAvatarReq) {
  // 1. Get pre-signed URL
  const res = await request<{ data: string }>({
    type: Method.PUT,
    url: `${API.Kessel}/me/avatar`,
    headers: { Authorization: `Bearer ${cookie().token}` },
    body: {
      content_length: image.size,
      content_type: image.type
    }
  });
  switch (res.type) {
    default:
    case Status.Failure: {
      return res;
    }
    case Status.Success: {
      // 2. Upload image to pre-signed URL
      return request({
        type: Method.PUT,
        url: res.body.data,
        headers: {
          'Content-Length': `${image.size}`,
          'Content-Type': image.type,
          'x-amz-acl': 'public-read'
        },
        body: image
      });
    }
  }
}
