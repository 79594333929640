// libraries
import React from 'react';
import { css } from '@emotion/core';

import Outside from 'components/OutsideLayout';
import { Vars } from 'style';

export default function Robot() {
  return (
    <>
      <Outside title="Forgot Password">
        <p css={prompt}>
          Your IP address has made more than 10 failed login attempts in 10
          minutes. Please try again after 1 hour.
        </p>
      </Outside>
    </>
  );
}

const prompt = css`
  font-weight: bold;
  text-align: center;
  font-size: ${Vars.FontSizeH4};
  line-height: ${Vars.LineHeightH4};
  margin-bottom: 2.4rem;
`;
