import { clear } from '@parsec/cookie';
import { setAuthRedirect, redirect } from '@parsec/redirect';

import * as config from 'config';

export const API = {
  Kessel: config.KESSEL_URL,
  Daemon: 'ws://127.0.0.1:5309'
};

// Auth

export interface Auth {
  instance_id: string;
  user_id: number;
  session_id: string;
}

export interface AuthErr {
  error: string;
  tfa_required?: boolean;
}

// User

export interface User {
  id: number;
  name: string;
  email: string;
  warp: boolean;
  staff: boolean;
  team_id: string;
  is_saml: boolean;
}

// Game

export interface Game {
  id: string;
  image_url: string;
  name: string;
  portrait_image_url: string;
}

// Host

export enum HostMode {
  Game = 'game',
  Desktop = 'desktop'
}

export interface Host {
  build: string;
  description: string;
  game: Game | null;
  max_players: number;
  mode: HostMode;
  name: string;
  peer_id: string;
  players: number;
  public: boolean;
  user_id: number;
  user: User;
}

// Access Link
export interface AccessLink {
  id: string;
  host_peer_id: string;
  starts_at: string;
  expires_at: string;
}

// Session

export enum SessionRole {
  Host = 'host',
  Client = 'client',
  User = 'user'
}

export enum SessionGrant {
  Anonymous = 'anonymous',
  AuthCode = 'auth_code',
  Password = 'password',
  Game = 'game_session'
}

export interface Session {
  id: string;
  roles: SessionRole[];
  client_peer_id: string;
  expires_at: number;
}

// Game Activation (for third party apps)

export enum GameActivationStatus {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
  Used = 'used'
}

export interface GameActivationState {
  expires_at: number;
  game: Game;
  status: GameActivationStatus;
}

// TFA

export interface TFABackupCode {
  code: string;
  used: boolean;
}

// Warp

export enum WarpInterval {
  Monthly = 'month',
  Yearly = 'year'
}

export enum WarpStatus {
  Active = 'active',
  Ending = 'ending',
  Canceled = 'canceled'
}

export interface WarpSubscription {
  status: WarpStatus;
  interval: WarpInterval;
  price: number;
  period_end: string;
  payment_issue: boolean;
}

// Team

export interface Team {
  id: string;
  alias: string | null;
  user_id: number;
  name: string;
  seats: number;
  num_members: number;
  num_invites: number;
  saml_session_duration: number;
  enforce_saml: boolean;
}

export interface TeamInvite {
  hash: string;
  team_id: string;
  email: string;
  expires_at: string;
  updated_at: string;
  created_at: string;
}

export interface TeamGroup {
  id: number;
  name: string;
  team_member_count: number;
  is_default: boolean;
}

export interface TeamMember {
  user_id: number;
  team_id: string;
  team_group_id: number;
  tag: string;
  is_admin: boolean;
  is_saml: boolean;
  last_connected_at: null | string;
  user: {
    name: string;
    email: string;
    user_id: number;
    avatar_url: string;
  };
}

export enum TeamSubscriptionStatus {
  Active = 'active',
  Ending = 'ending',
  Canceled = 'canceled'
}

export interface TeamSubscription {
  status: TeamSubscriptionStatus;
  active: boolean;
  period_end: string;
  payment_issue: boolean;
  is_managed: boolean;
}

export interface AppConfigSettingSchema {
  key: string;
  value: {
    type: 'boolean' | 'string';
    default: boolean | string;
    pattern?: string;
    title: string;
    description: string;
  };
}

export type AppConfigSchema = AppConfigSettingSchema[];

export interface AppConfig {
  [key: string]: {
    value: boolean | string;
  };
}

// Billing

export interface Card {
  address_line2: string;
  address_city: string;
  exp_month: string;
  name: string;
  last4: string;
  address_line1: string;
  address_state: string;
  exp_year: string;
  address_zip: string;
}

export interface SAML {
  sso_url: string;
  entity: string;
  certificate: string;
}

// Sales Tax

export interface Tax {
  totalAmount: number;
  totalTaxCalculated: number;
  taxSummary: {
    taxName: string;
    rate: number;
    taxCalculated: number;
  }[];
}

// Request/Response Utils

export enum Status {
  Idle,
  Pending,
  Success,
  Failure
}

export interface IdleRes {
  type: Status.Idle;
}

export interface PendingRes {
  type: Status.Pending;
}

export interface SuccessRes<T> {
  type: Status.Success;
  status: number;
  body: T;
}

export interface FailureRes<E> {
  type: Status.Failure;
  status: number;
  error: string;
  body?: E;
}

export type Res<T = unknown, E = { error?: string }> =
  | IdleRes
  | PendingRes
  | SuccessRes<T>
  | FailureRes<E>;

export enum Method {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE'
}

export type Req<T> =
  | {
      type: Method.GET;
      url: string;
      headers?: { [key: string]: string };
      noRedirect?: boolean;
    }
  | {
      type: Method.POST | Method.PUT | Method.PATCH | Method.DELETE;
      url: string;
      body?: T;
      headers?: { [key: string]: string };
      noRedirect?: boolean;
    };

export async function request<T, E = { error?: string }>(req: Req<any>) {
  let res: Response | null = null;

  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
      ...req.headers
    };

    // Make request
    res = await fetch(req.url, {
      method: req.type,
      headers,
      body:
        req.type !== Method.GET
          ? headers['Content-Type'] === 'application/json'
            ? JSON.stringify(req.body)
            : req.body
          : undefined
    });

    // Failed
    if (!res.ok || res.status >= 400) {
      // redirect to login
      if (res.status === 401 && !req.noRedirect) {
        clear();
        const { location } = window;
        setAuthRedirect(`${location?.pathname}${location?.search}`);
        redirect('/login');
      }
      let errorMessage = 'Request failed';
      try {
        if (res.ok) {
          const body = await res.json();
          if ('error' in body && typeof body.error === 'string') {
            errorMessage = body.error;
          }
        }
      } catch (err) {}
      throw new Error(errorMessage);
    }

    // Success
    let body: T = {} as T;
    if (res.status !== 204) {
      try {
        body = await res.json();
      } catch (err) {}
    }

    const ret: Res<T, E> = {
      type: Status.Success,
      status: res.status,
      body
    };

    return ret;
  } catch (err) {
    try {
      if (res && !res.ok) {
        // Error Response
        let body;
        try {
          body = await res.json();
        } catch (err) {}
        const ret: Res<T, E> = {
          type: Status.Failure,
          status: res.status,
          error: err.message,
          body
        };
        return ret;
      }
      throw err;
    } catch (err) {
      const ret: Res<T, E> = {
        type: Status.Failure,
        status: -1,
        error: err.message
      };
      return ret;
    }
  }
}
