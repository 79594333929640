import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';

import Icon from 'components/Icon';

import { palette } from 'style';
import usePropRef from 'hooks/usePropRef';

const ALERT_TIMEOUT_MS = 5000;

interface AlertWithTimeoutProps {
  kind?: 'error' | 'success';
  title: string;
  message: React.ReactNode;
  onClear?(): void;
}

export default function AlertWithTimeout({
  kind = 'error',
  title,
  message,
  onClear
}: AlertWithTimeoutProps) {
  const onClearRef = usePropRef<typeof onClear>(onClear);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    const onClear = onClearRef.current;
    if (onClear) {
      timeout.current = setTimeout(onClear, ALERT_TIMEOUT_MS);
    }
  }, [title, message, onClearRef]);

  return <Alert kind={kind} title={title} message={message} />;
}

export interface AlertProps {
  kind: 'error' | 'success';
  title: string;
  message: React.ReactNode;
}
function Alert({ kind, title, message }: AlertProps) {
  return (
    <div className={`${kind}-alert`} css={wrapper(kind)}>
      <Icon name={kind === 'error' ? 'ban' : 'check'} css={icon} />
      <strong>{title}</strong>
      <span>{message}</span>
    </div>
  );
}

export function useAlert() {
  const timeout = useRef<NodeJS.Timeout | null>(null);
  const [alert, setAlert] = useState<{
    kind: 'error' | 'success';
    title: string;
    message: React.ReactNode;
  } | null>(null);

  useEffect(() => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => setAlert(null), ALERT_TIMEOUT_MS);
  }, [alert]);

  const alertElem = alert ? <Alert {...alert} /> : null;
  return [alertElem, setAlert] as [JSX.Element, typeof setAlert];
}

const wrapper = (kind: 'error' | 'success') => css`
  ${palette(kind)}
  display: grid;
  grid-template-areas: 'icon title' 'icon copy';
  align-items: center;
  grid-column-gap: 1.8rem;
  position: fixed;
  top: 2.4rem;
  left: 50%;
  border-radius: 0.6rem;
  padding: 1.2rem 1.8rem;
  transform: translateX(-50%);
  z-index: 1000;
  max-width: calc(100vw - 20rem);
`;

const icon = css`
  grid-area: icon;
  width: 2.4rem;
  height: 2.4rem;
`;
