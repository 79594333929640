import React, { ReactElement, ReactText } from 'react';
import { css, Global } from '@emotion/core';

import Head from 'components/Head';
import Icon from 'components/Icon';

import logomark from 'images/logomark.svg';
import background from 'images/outside/background.png';

import global, { Vars } from 'style';
import { createRequestHook } from 'utils/requestHook';
import { getMe } from 'requests/me';
import { User, Status } from 'requests/api';
import Loader from './Loader';
import Alert from './Alert';
import {
  useRedirectWhenAuthenticated,
  useRedirectWhenUnauthenticated
} from 'hooks/useRedirect';

//
// <OutsideLayout>
// This is the main layout/wrapper Component for "outside" pages
// -----------------------------------------------------------------------------

interface OutsideLayoutAuthenticatedProps extends LoggedInProps {
  only: 'logged-in';
}
interface OutsideLayoutUnauthenticatedProps extends LoggedOutProps {
  only: 'logged-out';
}
interface OutsideLayoutLoggedInOrOutProps extends LoggedInOrOutProps {
  only?: never;
}
type OutsideLayoutProps =
  | OutsideLayoutAuthenticatedProps
  | OutsideLayoutUnauthenticatedProps
  | OutsideLayoutLoggedInOrOutProps;

/**
 * Renders an "outside" layout and supports optional auth redirect behavior
 */
export default function OutsideLayout(props: OutsideLayoutProps) {
  switch (props.only) {
    case 'logged-in': {
      const { only, ...rest } = props;
      return (
        <>
          <Global styles={global} />
          <LoggedIn {...rest} />
        </>
      );
    }
    case 'logged-out': {
      const { only, ...rest } = props;
      return (
        <>
          <Global styles={global} />
          <LoggedOut {...rest} />
        </>
      );
    }
    default: {
      const { only, ...rest } = props;
      return (
        <>
          <Global styles={global} />
          <LoggedInOrOut {...rest} />
        </>
      );
    }
  }
}

//
// <LoggedInOrOut> (private)
// -----------------------------------------------------------------------------

interface LoggedInOrOutProps {
  id?: string;
  title: string;
  // Note: this crazy signature is here to disallow render prop children
  children?:
    | ReactElement
    | Array<ReactElement>
    | ReactText
    | boolean
    | null
    | undefined;
}
/**
 * Allows the user to be either authenticatd or unauthenticted
 */
function LoggedInOrOut({ id, title, children }: LoggedInOrOutProps) {
  return (
    <div id={id} css={page}>
      <Head title={title} />
      <main css={wrapper}>
        <img src={logomark} alt="Parsec" css={logo} />
        {children}
        <ul css={info}>
          <li>
            <a css={infoLink} href="https://support.parsec.app">
              <Icon name="help" css={infoLinkIcon} />
              Help
            </a>
          </li>
          <li>
            <a css={infoLink} href="/terms">
              <Icon name="gavel" css={infoLinkIcon} />
              Terms
            </a>
          </li>
          <li>
            <a css={infoLink} href="/privacy">
              <Icon name="lock" css={infoLinkIcon} />
              Privacy
            </a>
          </li>
        </ul>
      </main>
    </div>
  );
}

//
// <LoggedOut> (private)
// -----------------------------------------------------------------------------

interface LoggedOutProps {
  id?: string;
  title: string;
  children: () => JSX.Element;
}
/**
 * Will redirect the user if they are authenticated
 */
function LoggedOut({ id, title, children }: LoggedOutProps) {
  const ok = useRedirectWhenAuthenticated();
  if (!ok) return null;
  return (
    <LoggedInOrOut id={id} title={title}>
      {children()}
    </LoggedInOrOut>
  );
}

const useGetMe = createRequestHook(getMe);

//
// <LoggedIn> (private)
// -----------------------------------------------------------------------------

interface LoggedInProps {
  id?: string;
  title: string;
  children: (user: User) => JSX.Element;
}
/**
 * Will redirect the user if they are unauthenticated or have a bad session
 */
function LoggedIn({ id, title, children }: LoggedInProps) {
  const ok = useRedirectWhenUnauthenticated();
  const [me] = useGetMe({
    skip: !ok
  });
  if (!ok) return null;

  switch (me.type) {
    case Status.Idle:
    case Status.Pending: {
      return (
        <LoggedInOrOut id={id} title={title}>
          <Loader css={{ width: '4rem', height: '4rem' }} />
        </LoggedInOrOut>
      );
    }
    case Status.Failure: {
      return (
        <LoggedInOrOut id={id} title={title}>
          <Alert title="Error" message={me.body?.error ?? me.error} />
        </LoggedInOrOut>
      );
    }
    case Status.Success: {
      return (
        <LoggedInOrOut id={id} title={title}>
          {children(me.body.data)}
        </LoggedInOrOut>
      );
    }
  }
}

//
// Styles
// -----------------------------------------------------------------------------

const page = css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${background}) no-repeat;
  background-size: cover;
  image-rendering: pixelated;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: bicubic;
  font-family: ${Vars.MainFont};
  min-height: 100vh;
  -ms-interpolation-mode: bicubic;
`;

const wrapper = css`
  padding: 2rem;
  background-color: ${Vars.BackgroundColor};
  border-radius: 0.8rem;
  font-size: ${Vars.FontSizeInformation};
  width: 42rem;
`;

const logo = css`
  display: block;
  height: 6rem;
  margin: 3rem auto;
`;

const info = css`
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  margin: 4.8rem 0 0;
`;

const infoLink = css`
  display: flex;
  align-items: center;
`;

const infoLinkIcon = css`
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.6rem;
  opacity: 0.45;

  a:hover > & {
    opacity: 1;
  }
`;
